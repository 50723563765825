.reachus-btn {
    display: flex;
}
.reachus{
        color: #fff;
        line-height: inherit;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        border-radius: 0;
        padding: 9px 15px;
        text-transform:uppercase;
        display: inline-block;
        font-family: Inter, sans-serif;
        letter-spacing: 2.25px;
}

.block-tabs {
    width: 100%;
    margin: auto;
    margin-top: 0px;
    margin-left: 0px;
}

.tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tab {
    flex: 1;
    padding: 30px 0px 0px 0px;
    text-align: left;
    border-top: none;
    position: relative;
    font-family: Space Grotesk, sans-serif;
    color: #f5f5f5;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 24px;
}

.active-tab {
    border: none;
    background: transparent;
    color: #a8fe39;
    z-index: 99;
}

.active-tab::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: initial;
    animation: new 0.5s;
}

.contents {
    position: relative;
}

.content {
    display: none;
    position: relative;
}

.active-content {
    display: block;
    position: relative;
    top: -160px;
    margin-left: 150px;
    margin-right: 10px;
    transition: all 1s ease-in-out;
    
}
.wrapper {
    grid-column-gap: 2vw;
    grid-row-gap: 2vw;
    grid-template-rows: auto auto;
    grid-template-columns: 32.5rem 32.5rem;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    display: grid;
}

.item {
    background-color: rgba(0, 0, 0, 0.08);
    outline-color: #A8FE39;
    backdrop-filter: blur(0.31px);
    border-radius: 7px;
    display: block;
    padding: 28px 34px;
    outline-width: 0.75px;
    outline-style: solid;
    box-shadow: 0 .3px rgba(168, 254, 57, .65), 0 0 16.66px -16px rgba(168, 254, 57, .5),
        inset 0 0 31.66px -20px rgba(168, 254, 57, .5);
}

.img-title-card {
    align-items: center;
    display: flex;
}

.card-title {
    letter-spacing: 2.34px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 6px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    font-family: Space Grotesk, sans-serif;
}

h4,
h2,
h3 {
    font-family: Space Grotesk, sans-serif;
}


.card-btn {
    height: 153px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 15px;
    display: flex;
}

.card-content {
    color:  #C5C5C5;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.59375rem;
    height: 6.5625rem;
}

.tab {
    position: relative;
    cursor: pointer;
    padding-left: 20px; /* Add some padding to the left side */
    transition: transform 0.3s;
  }
  
  .tab::before {
    content: "-";
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    transform-origin: left center; /* Set the transform origin to left side */
  }
  
  .tab:hover::before {
    opacity: 1;
  }
  .tab:hover {
    color: #A8FE39; /* Change text color to green on hover */
    transform: scale(1.1); /* Zoom in effect on hover */
  }


@media screen and (max-width: 480px) {
    .tabs {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .tab {
        flex: 1 1;
        padding: 10px;
        z-index: 1;
        justify-content: center;
        display: flex;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }


    .card-title {
        letter-spacing: 1px;
        font-size: 14.5px;
        line-height: 21px;
    }

    .card-content {
        font-size: 13px;
    }

    .reachus {
        font-size: 12px;
        line-height: 12px;
    }
}

@media screen and (max-width: 480px) {
    .contents {
        position: relative;
    }

    .content {
        display: none;
        position: relative;
    }

    .active-content {
        display: block;
        position: relative;
        top: 0;
        margin-left: 16px;
        margin-right: 16px;
        transition: all 1s ease-in-out;
        margin-bottom: 120px;
    }

    

    .item {
        background-color: rgba(0, 0, 0, 0.08);
        outline-color: #A8FE39;
        backdrop-filter: blur(0.31px);
        border-radius: 7px;
        display: block;
        padding: 28px 34px;
        outline-width: 0.75px;
        outline-style: solid;
        box-shadow: 0 .3px rgba(168, 254, 57, .65), 0 0 16.66px -16px rgba(168, 254, 57, .5),
            inset 0 0 31.66px -20px rgba(168, 254, 57, .5);
    }

    .img-title-card {
        align-items: center;
        display: flex;
    }

    .card-title {
        letter-spacing: 2.34px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 6px;
        font-size: 18px;
        line-height: 30px;
        font-weight: 400;
        font-family: Space Grotesk, sans-serif;
    }

    h4,
    h2,
    h3 {
        font-family: Space Grotesk, sans-serif;
    }

   

    .card-btn {
        height: auto;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 15px;
        display: flex;
    }
    .card-content {
        color: #C5C5C5;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18.342px; /* 152.851% */        
    }

    .reachus-btn {
        color: #f5f5f5;
        letter-spacing: 2.25px;
        text-transform: uppercase;
        align-items: center;
        font-family: Inter, sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        display: flex;
    }
    .reachus {
        background-color: rgba(0, 0, 0, 0);
        padding: 10px;
        color: #f5f5f5;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 2.25px;
        text-transform: uppercase;
    }
}
    



@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
    .tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: -10px;
        justify-items: center;
    }
    .active-content {
        display: block;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        top: 0;
        transition: all 1s ease-in-out;
    }
.reachus-btn {
    display: flex;
    margin-top: 15px;
}
.wrapper {
    grid-column-gap: 2vw;
    grid-row-gap: 2vw;
    grid-template-rows: auto auto;
    grid-template-columns: 22.5rem 22.5rem;
    grid-auto-columns: 1fr;
    justify-content: center;
    display: grid;
}
.tab {
    position: relative;
    cursor: pointer;
    padding-left: 0;
    transition: transform 0.3s;
    text-align: center;
}

}