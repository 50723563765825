



.contact-form{
    opacity: 1;
    width: 1143px;
    height: 560px;
    border-left: 1px solid #A8FE39;
    background-color: rgba(14,14,14,.5);
    margin: 14.5vh;
    position: fixed;
    top: -4%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    transform: translateX(10px);  z-index: 999;
  overflow-y: auto;

}

.form-holder {
    z-index: 5;
    height: 100%;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    backdrop-filter: blur(193.51px);
    background-color: rgba(14,14,14,.5);
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    padding: 62.53px 61.92px 87.89px 62.25px;
    display: grid;
    position: relative;
}

.contact-heading {
    font-size: 67.08px;
    font-weight: 400;
    line-height: 82px;
}
#form1{
    grid-area: 1 / 2 / 2 / 3;
}

.form-block {
    width: 100%;
    height: 100%;
    margin-top: 25px;
    margin-bottom: 0;
}

.form{
    margin: 0 0 15px;
}

.form-class {
    height: 100%;
    grid-column-gap: 59.34px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

    .text-field-div.submit {
        justify-content: space-between;
        display: flex;
    }
    
    .text-field {
        color: var(--typo);
        background-color: rgba(0, 0, 0, 0);
        border: 1px #000;
        border-bottom: 1px solid #fafafa;
    }
    label {
        margin-bottom: 5px;
        font-weight: bold;
        display: block;
    }
    .form {
        height: 100%;
        grid-column-gap: 59.34px;
        grid-row-gap: 16px;
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        display: grid;
    }

    .w-input {
        color: white;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        border-bottom: 1px solid #fafafa;
        margin-bottom: 25px;
        width: 400px;
        outline: none; /* Remove the default focus outline */
      }
    .form-control{
        color: white;
    background-color: rgba(0, 0, 0, 0);
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #fafafa;
    margin-bottom: 25px;
    width: 400px;

    }
    .form-label {
        color:  #C5C5C5;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 9.838px;
        margin-bottom: 15px;
    }
  .btn
    {
     color: #0e0e0e;
    background-color: #a8fe39;
    border-radius: 13.76px;
    padding: 14px 65px;
    font-family: Space Grotesk, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 27.5px;
    }

    .close-btn {
        z-index: 50;
    width: auto;
    height: auto;
    opacity: 1;
    color: white;
    mix-blend-mode: screen;
    font-family: Inter, sans-serif;
    font-size: 42px;
    font-weight: 200;
    line-height: 42px;
    position: absolute;
    top: 2%;
    bottom: auto;
    left: auto;
    right: 5%;
    background: none;
    }



    @media screen and (max-width: 480px){
        .contact-form{
            width: 87vw;
        height: 85vh;
        margin: 120px 24px 0;
        border-top: 1px solid #a8fe39;
        border-left-style: none;
        transform: none;
        overflow: hidden;
    }
        
        .form-holder {
            width: 100%;
            grid-row-gap: 0px;
            background-color: #0e0e0e;
            grid-template-rows: .5fr;
            grid-template-columns: minmax(247px, 1fr);
            padding: 10px 30px;            
        }
        .contact-heading {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 32px;
            line-height: 40px;
            position: relative;
        }

        #form1{
            grid-area: 2 / 1 / 3 / 2; 
            
        }
        .close-btn {
            z-index: 70;
            font-size: 38px;
            font-weight: 200;
            line-height: 32px;
            position: absolute;
            top: 5px;
            bottom: auto;
            left: auto;
            right: -30px;
        }

        form {
            position: relative;
            right: 0px;
            margin-top: 20px;
        }

        .form-control{
            width: 150%;
        }
        .box {
            height: 698px;
            width: 339px;
        }
        
        .box .group {
            height: 698px;
            left: 0;
            position: fixed;
            top: 0;
            width: 339px;
        }
        
        .box .contact-overlay {
            backdrop-filter: blur(191.69px) brightness(100%);
            background-color: #0d0d0d33;
            border-color: #a8fe39;
            border-top-style: solid;
            border-top-width: 0.71px;
            box-shadow: 11.32px 11.32px 31.13px #00000033;
            height: 698px;
        }
        
        .box .frame {
            align-items: center;
            display: inline-flex;
            flex-direction: column;
            gap: 8px;
            left: 46px;
            position: relative;
            top: 140px;
        }
        
        .box .div {
            align-items: center;
            display: inline-flex;
            flex: 0 0 auto;
            flex-direction: column;
            gap: 16px;
            position: relative;
        }
        
        .box .check-circle {
            height: 56px;
            position: relative;
            width: 56px;
        }
        
        .box .text-wrapper {
            color: #ffffff;
            font-family: "Space Grotesk-Regular", Helvetica;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: -1px;
            line-height: 32px;
            position: relative;
            text-align: center;
            width: 246px;
        }
        
        .box .p {
            color: #ffffff;
            font-family: "Inter-Regular", Helvetica;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 20px;
            opacity: 0.7;
            position: relative;
            text-align: center;
            width: 236px;
        }
        
    }

    @media only screen   
    and (orientation : landscape)   
    and (-webkit-min-device-pixel-ratio: 1)  
    and (min-device-width : 768px)   
    and (max-device-width : 1024px)  {
    .close-btn {
        right: 0;  
        left: 780px;;  
    }
    .contact-form {
        opacity: 1;
        width: 950px;
        height: 560px;
        border-left: 1px solid #A8FE39;
        background-color: rgba(14,14,14,.5);
        margin: 14.5vh;
        position: fixed;
        top: -2%;
        bottom: 0%;
        left: -90px;
        right: 0%;
        transform: translateX(10px);
        overflow-y: hidden;
    }
    .form-holder {
        grid-column-gap: 20px;
    }
    }
    @media only screen 
    and (min-width: 600px)
    and (max-width: 1264px) {
    .contact-form {
        margin: 0;
        top: 250px;
    backdrop-filter: blur(90px);
    width: auto;
    }
    .contact-heading {
        font-size: 47.08px;
        font-weight: 400;
        line-height: 72px;
    }
}