
img.scrolling {
  margin: 0;
  z-index: 1;
  position: relative;
  animation: spin 5s linear infinite;
  position: relative;
  top: 30px;
  left: 85px;
}
.scrolling-circle {
  width: 169px;
  height: 169px;
  flex-shrink: 0;
  display: block;

}
.photo-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
img.photo {
  display: flex;
  width: 1013.25px;
  height: 546.5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
  flex-shrink: 0;
}
.slider-container {
  display: flex;
  height: 505.5px;
  padding: 0px 0px 0.026px 0px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}
.scrolling:hover {
  animation-play-state: paused;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Style each slide */
.slide {
  display: none;
  width: 100%;
  height: 100%;
}


.slider {
  display: flex;
  justify-content: center;
  margin-right: 150px;
  margin-left: 0;
  margin-top: 100px;
}
/* Caption text */
.caption {
display: none;
}
.dots {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  gap: 18px;
}

.dots-container {
  margin-top: 20px;
}

.dot {
  position: relative;
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin: 0;
  background-color: transparent; /* Inner circle fill color */
  border: 0.75px solid #ccc; /* Outer circle stroke color */
  border-radius: 50%;
  display: inline-block;
  transition: 0.5s;
}

.dot::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px; /* Adjust size for inner circle */
  height: 15px; /* Adjust size for inner circle */
  background-color: #A8FE39;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s;
}

.dot:hover::before,
.dot.active2::before {
  opacity: 1; /* Show on hover or active */
}

.dot:hover {
  border-color: #A8FE39;
}

.active2 {
  border-color: #A8FE39;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}


@media screen and (max-width: 480px){
  .dots {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    gap: 31px;
    top: 0;
    justify-content: center;
    left: 80px;
    margin-bottom: 0;
    z-index: 1;
}
.scrolling-circle {
  display: none;
}
img.scrolling {
  margin: 0;
        z-index: 1;
        position: relative;
        animation: spin 5s linear infinite;
        width: 88px;
        height: 88px;
        position: relative;
        top: 100px;
        right: 10px;
        padding: 10px;
}
.slider {
  display: block;
  justify-content: center;
  margin-bottom: 0;
    margin-top: 0;
    margin-right: 0;
}
.slider-container {
  display: flex;
  height: 174.611px;
  padding: 0;
  justify-content: flex-end;
  align-items: center;
  margin: 20px;
}
.dots-container {
  margin-top: -20px;
}

img.photo {
  display: flex;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.slider {
  margin-top:0;
}
}

@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
  .slider {
    display: flex;
    justify-content: center;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 0;
    flex-direction: column;
}

.scrolling-circle {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  display: block;
}
.photo-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.slider-container {
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: auto;
}
img.scrolling {
  margin: 0;
  z-index: 1;
  position: relative;
  animation: spin 5s linear infinite;
  position: relative;
  top: 140px;
  left: -30px;
}
img.photo {
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: inherit;
  flex-shrink: 0;
}

}