.walkfin {
    width: 1008.49px;
    height: auto;
    flex-shrink: 0;
    position: relative;
    top: 90px;
}
.fontstyle{
    display: none ;
}
.img-title-card-hex {
    display: flex;
    gap: 0;
}
.card-title1 {
    color: #a8fe39;
    letter-spacing: -0.25px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
    width: auto;
}
.img-title-cards3 {
    display: flex;
    flex-direction: row;
    gap: 176px;
}
div#ideate-container-sec {
    margin-top: 30px;
}
img.hexcode1 {
    width: 40%;
    height: auto;
    flex-shrink: 0;
    margin-left: 235px;
}
img.hexcode2 {
    width: 70%;
    margin-left: 165px;
    margin-top: -90px;
}
div#wireframe-container-sec {
    margin-top: 0;
}
ul.ul-walkfins {
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style-type: unset;
    margin-left: 15px;
    margin-top: 30px;
    font-size: 18px;
}
  ul.ul-walkfins li::marker {
    color: #A8FE39; /* Set the color of the bullet points */
  }
  .img-title-card1 {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 130px;
    margin-top: 0;
}

img.wireframeimg {
    width: 981.75px;
    height: auto;
    flex-shrink: 0;
    margin-top: 55px;
}
div#discover-container-sec {
    margin-top: 60px;
}
.img-titles-card4 {
    display: flex;
    flex-direction: row;
    gap: 153px;
}
img.aa {
    width: 278px;
    height: auto;
    flex-shrink: 0;
}
img.fontstyle1 {
    width: 305px;
    height: auto;
    flex-shrink: 0;
}

p.card-title-p1 {
    width: 704px;
    height: 29px;
    flex-shrink: 0;
    color: #C5C5C5;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
img.hex1 {
    width: 298px;
    height: 89.764px;
    flex-shrink: 0;
}
.img-title-card-hex {
    display: flex;
    gap: 0;
}
div#ideate1-container-sec {
    margin-bottom: 0;
}
div#wireframe-container-sec {
    margin-top: -90px;
}
h4.card-title1-hex {
    color: #FFF;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.25px;
}
div#efficiency-container-sec {
    margin-top: 60px;
}
img.hex2 {
    width: 522.571px;
    height: auto;
    flex-shrink: 0;
    margin-top: -100px;
    margin-left: -35px;
}
.img-title-card-pc1 {
    display: flex;
    gap: 69px;
}
.img-title-cards-pc1 {
    display: flex;
    gap: 69px;
}
img.pc1 {
    width: 53%;
    height: auto;
    flex-shrink: 0;
    margin-top: -50px;
}
img.pc2 {
    width: 53%;
    height: auto;
    flex-shrink: 0;
    margin-top: 0;

}
div#enhanced-container-sec {
    margin-top: 113px;
}
h4.card-title1-pc2 {
    margin-top: 0;
}
p.card-title-pc1 {
    width: 383px;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

h4.card-title1-pc1 {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
    margin-bottom: 15px;
}
h4.card-title1-pc2 {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
    margin-bottom: 15px;
}

h4.card-title1-hex2 {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.25px;
}
.img-title-card-hex2 {
    display: flex;
}
p.card-title-p2 {
    width: 468px;
    color:  #C5C5C5;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
img.pc3 {
    width: 53%;
    height: auto;
    flex-shrink: 0;
}

p.card-title-pc3 {
        width: 388px;
        position: relative;
        top: 0;
        color: #C5C5C5;

/* Body text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 166.667% */
}
.img-title-card-pc3 {
    display: flex;
    gap: 41px;
    margin-top: 76px;
}
h4.card-title1-pc4 {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
    margin-top: 0;
    margin-bottom: 15px;
}
img.pc4 {
    width: 51%;
    height: auto;
    flex-shrink: 0;
    margin-top: 0;
}
div#workflow-container-sec {
    margin-top: 60px;
}
p.card-title-pc4 {
    width: 406px;
}
.img-title-card-pc4 {
    display: flex;
    gap: 47px;
}
img.pc5 {
    width: 566px;
    height: auto;
    flex-shrink: 0;
    margin-top: 38px;
}
p.card-title-pc5 {
    margin-top: 0;
    width: 410px;
}
.img-title-card-pc5 {
    display: flex;
    gap: 23px;
    margin-top: 60px;
}
.img-title-card-achieved {
    display: flex;
    gap: 149px;
}
h4.card-title1-achieved {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
}
p.card-title-achieved {
    display: flex;
    width: 711px;
    height: 54px;
    flex-direction: column;
    justify-content: flex-end;
    flex-shrink: 0;
}
div#achieved-container-sec {
    margin-top: 67px;
}


@media screen and (max-width: 480px) {
    .img-title-card1 {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 0;
    }
div#discover-container-sec {
    margin-top: 0;
}
.img-title-cards3 {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
}

img.wireframeimg {
    width: auto !important;
    height: auto;
    flex-shrink: 0;
    margin-top: 0 !important;
}
.img-titles-card4 {
    display: flex;
    flex-direction: column !important;
    gap: 0 !important;
    align-items: flex-start;
}
div#ideate-container-sec {
    margin-top: 50px;
}
.font {
    position: relative;
    top: -160px;
}
.secondary {
    position: relative;
    top: 110px;
}
.hexcodes {
    position: relative;
    top: -90px;
}
img.hexcode1 {
    margin-left: 0;
    width: auto;
    padding: 10px;
}
div#wireframe-container-sec {
    margin-top: 150px;
}
img.fontstyle {
    display: block;
}
img.hexcode2 {
    width: 70%;
    margin-left: 0;
    margin-top: 30px;
}
.img-title-card-hex {
    display: flex;
    gap: 100px;
    flex-direction: column-reverse;
}
img.hexcode2 {
    margin-bottom: -280px;
}
div#efficiency-container-sec {
    margin-top: 0;
}
img.pc1 {
    width: auto;
    height: auto;
    flex-shrink: 0;
    margin-top: -50px;
}
h4.card-title1-pc1 {
    font-size: 18px;
    letter-spacing: -0.25px;
    margin-bottom: 0;
}
.img-title-card-pc1 {
    display: flex;
    gap: 69px;
    flex-direction: column;
}
h4.card-title1-pc2 {
    font-size: 18px;
    letter-spacing: -0.25px;
    margin-bottom: 0;
}
h4.card-title1-pc2 {
    margin-top: 30px;
}
.img-title-card-pc3 {
    display: flex;
    gap: 12px;
    margin-top: 0;
    flex-direction: column;
}
p.card-title-pc3 {
    position: relative;
    top: 0;
}
h4.card-title1-pc4 {
    font-size: 18px;
    letter-spacing: -0.25px;
    margin-top: 30px;
    margin-bottom: 0;
}
.img-title-card-pc4 {
    display: flex;
    gap: 60px;
    flex-direction: column-reverse;
}
div#workflow-container-sec {
    margin-top: -20px;
}
.img-title-card-pc5 {
    display: flex;
    gap: 12px;
    flex-direction: column;
    margin-top: 0;
}
img.pc5 {
    width: auto;
    height: auto;
    flex-shrink: 0;
    margin-top: 0 !important;
}
p.card-title-pc5 {
    margin-top: 0;
}
div#achieved-container-sec {
    margin-top: 40px;
}
.img-title-card-achieved {
    display: flex;
    gap: 60px;
    flex-direction: column;
}
h4.card-title1-achieved {
    font-size: 18px;
    letter-spacing: -0.25px;
}
.walkfin {
    width: auto;
    height: auto;
    flex-shrink: 0;
    position: relative;
    top: 0;
    margin-top: 0;
}

.fontstyle1{
    display: none !important;
}
.card-title1 {
    color: #a8fe39;
    letter-spacing: -0.25px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Space Grotesk, sans-serif;
    font-size: 18px;
    font-weight: 400;
    width: auto;
}
.img-title-cards-pc1 {
    display: flex;
    gap: 12px;
    flex-direction: column-reverse;
}
div#enhanced-container-sec {
    margin-top: -20px;
}
img.pc2 {
    width: auto;
    height: auto;
    flex-shrink: 0;
    margin-top: 0;
}
ul.ul-walkfins {
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style-type: unset;
    margin-left: 15px;
    margin-top: -10px;
    font-size: 18px;
}
.nav-main-div {
    display: none;
}
div#walkfin-section {
    margin-top: 90px;
}
}

@media only screen and (min-width: 600px) and (max-width: 1264px) {
    /* .main-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0;
        justify-items: flex-start;
        margin-left: 0;
        margin-right: 0;
    } */
    .walkfin {
        height: auto;
        flex-shrink: 0;
        position: relative;
        top: 70px;
        margin-top: 0;
    }
    .img-title-card1 {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-top: 0;
    }
    .embedded-contact-form {
        display: none;
    }
    .img-title-cards3 {
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-top: 0;

    }
    img.wireframeimg {
        width: 850px;
    }
    .img-title-card-hex {
        display: flex;
        gap: 70px;
        flex-direction: column-reverse;
    }
    img.pc1 {
        width: 43% !important;
        height: auto;
        flex-shrink: 0;
        margin-top: -50px !important;
    }
    img.pc2 {
        width: 43% !important;
        height: auto;
        flex-shrink: 0;
        margin-top: 0 !important;
    }
    img.pc3 {
        width: 43% !important;
        height: auto;
        flex-shrink: 0;
    }
    img.pc4 {
        width: 43% !important;
        height: auto;
        flex-shrink: 0;
        margin-top: 0 !important;
    }
    img.pc5 {
        width: 43% !important;
        height: auto;
        flex-shrink: 0;
        margin-top: 0 !important;
    }
    .img-title-card-achieved {
        display: flex;
        gap: 10px;
        flex-direction: column;
    }
    img.wireframeimg {
        width: 93%;
        height: auto;
        flex-shrink: 0;
        margin-top: 0;
    }
    p.card-title-pc1 {
        width: 340px;
    }
    p.card-title-pc2 {
        width: 340px;
    }
       
    p.card-title-pc3 {
        width: 340px;
    }
    p.card-title-pc4 {
        width: 340px;
    }
    p.card-title-pc5 {
        width: 340px;
    }
       
       
        
}