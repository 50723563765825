.footer-section-mobile.mobile {
    width: auto;
    justify-content: center;
    align-items: center;
    margin-top: 16.3rem;
        margin-left: 10.83rem;
    margin-right: 10.61rem;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    z-index: 99;
}
span.expand-icon {
    margin-top: 10px;
    margin-right: 20px;
}
.footer-text-link.active1 {
    color: #A8FE39; /* Active link color */
  }
  
  .footer-text-link:hover {
    color: #A8FE39; /* Hover color */
  }
.footer-section-mobile {
    background-color: #0e0e0e;
    margin-top: 187.5px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 173px;
    padding-bottom: 80px;
}
.footer-grid {
    width: 90vw;
    max-width: 1458px;
    grid-column-gap: 197px;
    grid-row-gap: 84px;
    grid-template-rows: auto auto;
    grid-template-columns: minmax(352.5px, 1fr) minmax(545px, 1fr);
    grid-auto-columns: 1fr;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    display: grid;
}

#footer1{
    grid-area: span 1 / span 1 / span 1 / span 1;
}

.footer-icon {
    margin-bottom: 40px;
}
.footer-tag-text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 36px;
    line-height: 42px;
}
.expand-icon {
    font-family: Material design iconic font, sans-serif;
}
.link-expand-icon {
    color: #a8fe39;
    font-style: normal;
}

#footer2{
    grid-area: span 1 / span 1 / span 1 / span 1;
}

.footer-right-clm {
    grid-column-gap: 84px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: minmax(335.25px, 1fr) minmax(126px, 1fr);
    grid-auto-columns: 1fr;
    display: grid;
}

#footer-2{
    grid-area: span 1 / span 1 / span 1 / span 1;
}
.footer-address-div {
    height: 177px;
}
.ouraddress-txt {
    color: #a8fe39;
    margin-bottom: 14px;
    font-family: Space Grotesk, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
}

.address-txt {
    color: #a7a7a7;
    font-family: Inter, sans-serif;
    font-size: 18px;
    line-height: 27.8px;
}

#footer3{
    grid-area: span 1 / span 1 / span 1 / span 1;
}
.footer-links-div {
    flex-direction: column;
    display: flex;
}
.footer-text-link {
    color: #a7a7a7;
    padding-bottom: 20px;
    font-family: Space Grotesk, sans-serif;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;
}
#footer4{
    grid-area: span 1 / span 2 / span 1 / span 2;
}

.footer-copyrights-div {
    border: 0.75px #000;
    border-top: 0.75px solid #616161;
}

.footer-copyright-text {
    text-align: center;
    padding-top: 18px;
    font-family: Space Grotesk, sans-serif;
    font-size: 15px;
    line-height: 30px;
}

.ellipse-div.footer
 {
    z-index: -10;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}
h4.footer-tag-text1 {
    display: flex;
    gap: 10px;
}



@media screen and (max-width: 480px){
h4.footer-tag-text1 {
    display: none;
  }

  #footer2{
    grid-area: 2 / 1 / 2 / 3;
    margin-top: -70px;

  }
#footer-2 {
    grid-area: 2 / 1 / 3 / 2;
}
.address-txt {
    font-size: 12px;
    margin-right: 200px;
}
#footer3 {
    grid-area: 1 / 1 / 2 / 2;
}
.footer-text-link {
    font-size: 14px;
    line-height: 20px;
}
#footer4 {
    grid-area: 3 / 1 / 4 / 3;
    z-index: 1;
}
.footer-copyright-text {
    width: 32%;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    text-align: center;
}
div#footer4 {
    position: relative;
    top: -90px;
}
}



  @media only screen 
  and (min-width: 600px)
  and (max-width: 1264px) {
    .footer-section-mobile.mobile {
        width: auto;
        justify-content: center;
        align-items: center;
        margin-top: 14.3rem;
        margin-left: 50px;
        margin-right: 50px;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        z-index: 99;
    }
    span.expand-icon {
        margin-top: 5px;
        margin-right: 20px;
    }
    .footer-grid {
        display: flow;
    }
    div#footer2 {
        margin-top: 30px;
    }
    .footer-right-clm {
        grid-column-gap: 250px;
    }

    }