
.client-tag-heading {
    margin-left: auto;
    margin-right: auto;
}
div#marquee-sec {
  margin-top: -80px;
}
.heading-text {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 37.5px;
}

.span2{
    color: #A8FE39;;
}
.image_wrapper {
    margin: 0px 30px;
  }
  
  .image_wrapper img {
    width: 80%;
  }

  @media screen and (max-width: 480px){
    .image_wrapper {
          margin-right: 40px;
          height: 80%;
          width: 80%;
      
      }
      .heading-text {
        margin-top: 100px;
      }
      div#marquee-sec {
        margin-top: -200px;
    }
  }
  


  @media only screen 
and (min-width: 600px)
and (max-width: 1264px) {

.client-tag-heading {
    margin-top: 80px;
}
}