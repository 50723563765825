.privacy-h3{
    
    flex-shrink: 0;
    color: #C5C5C5;
    font-family: "Space Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 13px;  
}
.policy {
    margin-top: 27px;
}
.privacy-p{
    width: 910px;
flex-shrink: 0;
color:  #C5C5C5;
/* Body text */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 166.667% */

}

.policies {
    position: relative;
    top: 165px;
    left: 142px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .policies {
        position: relative;
        top: 145px;
        left: auto;
        margin: 16px;
    }
.privacy-p {
    width: auto;
}

}