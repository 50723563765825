/* CaseStudyNavbar.css */
.case-study-sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(5px);
    box-shadow: 0 5px 15px rgba(14, 14, 14, 0.2);
    z-index: 1000;
    background-color: rgba(14, 14, 14, 0.9);
  }
  
  .case-study-navbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 57px;
    height: 88px;
    font-family: 'Space Grotesk', sans-serif;
  }
  
  .case-study-menu-icons {
    display: none;
  }
  
  .case-study-contact-us-btn-nav {
    background-color: rgba(236, 254, 8, 0.1);
    border: 0.75px solid #ecfe08;
    border-radius: 12px;
    padding: 0.65625rem 1.875rem;
    color: #ecfe08;
    font-size: 1.2vw;
    cursor: pointer;
  }
  
  .case-study-back-to-home-btn {
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 0.75rem;
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.59375rem; /* 170% */
  }
  
  .case-study-back-to-home-btn img {
    margin-right: 5px;
  }
  
  .case-study-nav-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center the links vertically */
    background-color: rgba(14, 14, 14, 0.9);
    position: fixed;
    top: 0; /* Make sure it covers the entire screen */
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    list-style: none;
    padding: 20px 0;
    margin: 0;
  }
  
  .case-study-nav-menu.active1 {
    display: flex;
  }
  
  .case-study-nav-links {
    margin: 20px 0;
  }
  
  .case-study-nav-links a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .case-study-nav-links a:hover {
    color: #A8FE39;
  }
  
  @media (max-width: 767px) {
    .case-study-menu-icons {
      display: block;
      color: #fff;
      font-size: 24px;
    }
  
    .case-study-contact-us-btn-nav {
      display: none;
    }
  
    .case-study-back-to-home-btn {
      margin-right: 10px;
    }
  
    .case-study-navbarItems {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      height: 88px;
      font-family: 'Space Grotesk', sans-serif;
    }
  
    .case-study-nav-menu {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(14, 14, 14, 0.9);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      list-style: none;
      padding: 20px 0;
      margin: 0;
    }
  
    .case-study-nav-menu.active1 {
      display: flex;
    }
  
    .case-study-nav-links {
      margin: 20px 0;
    }
  
    .case-study-nav-links a {
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      transition: color 0.3s ease;
    }
  
    .case-study-nav-links a:hover {
      color: #A8FE39;
    }
  
    .case-study-menu-icons {
        display: block;
        color: #fff;
        font-size: 24px;
        z-index: 1000;
    }
    .case-study-nav-links a:hover,
.case-study-nav-links a.active1 {
  color: #A8FE39; 
}

  }
  