.helping{
    color: white;
}
.stories-case{
    /* width: 100vw;
    height: 80vh; */
    background-color: #0e0e0e;
        justify-content: center;
    align-items: center;
    margin-top: 0;
    display: flex;
    position: relative;
    overflow: hidden;
    height: 600px;
}
div#stories1 {
    padding-left: 8.65rem;
}
/* .stories-img-div{
    width: auto;
    height: auto;
    grid-column-gap: 5vw;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: minmax(36.875rem, 1fr) minmax(51.6rem, 1fr);
    grid-auto-columns: 1fr;
    align-content: stretch;
    justify-content: start;
    align-items: stretch;
    margin: auto;
    padding-left: 0;
    display: grid;

} */

p-stories {
    color: #c5c5c5;
    margin-bottom: 10px;
    font-size: 1.35rem;
    line-height: 1.9625rem;
    width: 35.875rem;
}

.stories-heading {
    letter-spacing: -2.25px;
    margin-top: 0;
    margin-bottom: 24px;
    font-family: Space Grotesk, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    line-height: 3.75rem;
    color: #a8fe39;
}
#stories1{
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
} 


#stories2{
    grid-area: span 1 / span 1 / span 1 / span 1;
}
.imgcase{
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
    background-position: 50%;
    background-size: cover;
    margin: auto;
    position: absolute;
    top: -100%;
    bottom: -100%;
    left: -50%;
        right: -100%;
}
ellipse-div.stories {
    width: 60vw;
    height: 100vh;
    background-image: linear-gradient(to right, #59ebe8, #af3d8c);
    margin-top: auto;
    margin-bottom: auto;
    display: none;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: auto;
}

.stories-img-holder {
    height: 100%;
}
.stories-img-holder {
    width: 80%;
    mix-blend-mode: normal;
    position: relative;
}

#stories3{
    grid-area: span 1 / span 1 / span 1 / span 1;
}

.imgcase {
    mix-blend-mode: screen;
    display: none;
}

.bgvideo {
    width: 200%;
    height: 100%;
    mix-blend-mode: screen;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: -51%;
    right: 0%;
}
.w-background-video {
    height: auto;
    color: #fff;
    position: relative;
    overflow: hidden;
}
.w-background-video > video {
    width: 80%;
    height: auto;
    object-fit: cover;
    z-index: -100;
    background-position: 50%;
    background-size: cover;
    margin: auto;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.stories-img-div {
    display: flex;
    flex-direction: row;
}
/* @media screen and (min-width: 1920px){
    .w-background-video > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -100;
      background-position: 50%;
      background-size: cover;
      margin: auto;
      position: absolute;
      top: -60%;
      bottom: -90%;
      left: -100%;
      right: -100%;
  }
  
  .stories-heading {
      font-size: 58px !important;
      line-height: 110%;
  }
  .p-stories {
      font-size: 1.1vw !important;
      line-height: 33px;
      margin-bottom: 20px;
  }
    } */
    
    @media screen and (max-width: 480px){
        .stories-heading{
            width: 20.5rem;
letter-spacing: 0;
font-family: "Space Grotesk";
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: 1.875rem; /* 150% */
        }
        div#stories1 {
            padding-left: 0;
        }
        .bgvideo {
            width: auto;
            height: 100%;
            mix-blend-mode: screen;
            position: absolute;
            top: 0;
            bottom: 0%;
            left: 0;
            right: 0%;
        }
        .w-background-video > video {
            width: 120%;
            height: auto;
            object-fit: cover;
            z-index: -100;
            background-position: 50%;
            background-size: cover;
            margin: auto;
            position: relative;
          top: -50px;
            margin-bottom: 0;
            left: 0;
            right: 0;
        }
        .p-stories {
            letter-spacing: -1px;
            margin-right: 0;
            margin-bottom: 0;
            color:  #C5C5C5;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5625rem; /* 156.25% */
        }
        .stories-case {
            height: auto;
            margin-top: 4.6rem;
        }
        .stories-img-div {
            width: auto;
            height: auto;
            grid-column-gap: 5vw;
            grid-row-gap: 16px;
            grid-template-rows: auto;
            grid-template-columns: minmax(36.875rem, 1fr) minmax(51.6rem, 1fr);
            grid-auto-columns: 1fr;
            align-content: stretch;
            justify-content: flex-start;
            align-items: stretch;
            margin: auto;
            padding: 2vh;
            display: flex;
            flex-direction: column;
        }
         .stories-img-holder {
            width: auto;
            mix-blend-mode: normal;
            position: relative;
        }
        .w-background-video {
            height: 240px;
            color: #fff;
            position: relative;
            overflow: hidden;
        }
    }

  
  @media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
    .stories-case {
        background-color: #0e0e0e;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        display: flex;
        position: relative;
        overflow: hidden;
        height: auto;
        margin-left: 50px;
        margin-right: 50px;
    }
    .stories-img-div {
        display: flex;
        flex-direction: column;
    }
    div#stories1 {
        padding-left: 0;
    }
    div#stories2 {
        display: none;
    }




}