.icon-box-section1 {
    display: flex;
    gap: 12px;
    flex-direction: row;
    justify-content: center;
}
.icon-box-section2 {
    display: flex;
    gap: 12px;
    flex-direction: row;
    margin-top: 15px;
    justify-content: center;

}
  .icon-box {
    width: 320px;
height: 142px;
flex-shrink: 0;
border-radius: 4px;
background: #252525;
padding: 12px;
  }
  
  .icons {
    width: 40px;
height: 40px;
flex-shrink: 0;
margin-bottom: 15px;
  }
  
  .icon-p{
    color: #C5C5C5;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28.509px; /* 178.184% */
  }



  @media screen and (max-width: 480px) {
  .icon-box-section2 {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .icon-box-section1 {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  .icon-box{
    width: auto;
  }
}


@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
  .icon-box {
    width: 350px;
    height: 142px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #252525;
    padding: 12px;
}
.icon-box-section1 {
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: unset;
}

.icons-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.icon-box-section2 {
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-top: 0;
  justify-content: unset;
}

}