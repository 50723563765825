
li.nav-links.privacy {
    display: none;
}


.navbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 57px;
        background-color: rgba(14, 14, 14, .2);  
    backdrop-filter: blur(5px); 
     box-shadow:0 5px 15px rgba(14, 14, 14, 0.2);
    width: 100%;
    height: 88px;
    font-family: 'Space Grotesk', sans-serif;
    z-index:3!important;
     position:fixed;


}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}
.navDesktopMenuBtn{
    padding: 8px 24px;
    border-style: solid; 
    border-width: 0.75px;
    border-color: #ecfe08;
    border-radius: 12px;
    background-color: rgba(236, 254, 8, 0.1);
    font-family: 'Space Grotesk', sans-serif;
    color: #ecfe08;
    font-size: 1.2vw;
    line-height: 30px;
    }
    .nav-links {
        list-style: none;
        padding: 0;
        position: relative;
        margin-left: 24px;
        margin-right: 24px;
        font-family: "Space Grotesk";
    font-size: 0.84375rem;
    }
.nav-links a{
            text-decoration: none;
            color: #a7a7a7;
            font-size: 0.84375rem;
        font-weight: 300;
        } 
.nav-links a.active1,.nav-links a:hover
        {
            color: #a8fe39;
        }

.fa-bars-sort, .fa-times{
    color: #fff;
}

.menu-icons{
    display: none;
}

@media screen and (max-width: 480px) {
    .navbarItems{
            padding: 0 1rem;   
         }
            li.nav-links.privacy {
                display: block;
            }
            
            
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #0E0E0E !important;
        box-shadow: 0 8px 32px 0 rgba(31,38,135,.37);
        backdrop-filter: blur(4px);
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -110%;
        align-items: stretch;
        padding: 190px 0 190px 0;
                margin: 0;
        z-index: -1;
    }
.nav-menu.active1{
    left: 0%;
}
    .nav-links a{
display: block;
widows: 100%;
font-size: 18px;
padding: 0;
color: #fff;
    } 
.nav-links:hover{
    backdrop-filter: blur(20px);
 }
.menu-icons{
    display: block;
} 

.navDesktopMenuBtn{
    display: none;
}
}

@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
    .navbarItems{
        padding: 0 1rem;   
     }
        li.nav-links.privacy {
            display: block;
        }
        
        
.nav-menu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #0E0E0E !important;
    box-shadow: 0 8px 32px 0 rgba(31,38,135,.37);
    backdrop-filter: blur(4px);
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 190px 0 190px 0;
            margin: 0;
    z-index: -1;
}
.nav-menu.active1{
left: 0%;
}
.nav-links a{
display: block;
widows: 100%;
font-size: 18px;
padding: 0;
color: #fff;
} 
.nav-links:hover{
backdrop-filter: blur(20px);
}
.menu-icons{
display: block;
} 

.navDesktopMenuBtn{
display: none;
}
ul#navbarMenu {
    gap: 45px;
}
.navDesktopMenuBtn1 {
    z-index: 5;
    height: 72px;
    background-color: #a8fe39;
    color: #000;
    border-width: 0.75px;
    border-color: #ecfe08;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: Space Grotesk, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    position: fixed;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
    border-radius: 0px;
    border: none;
    display: block !important;
    }
}

  