.walkfin1 {
    width: 1008.49px;
    height: auto;
    flex-shrink: 0;
    position: relative;
}
.main-wrapper1 {
    width: auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 16vh;
    padding-left: 0;
    padding-right: 0;
    display: flex;
}
.main-wrapper1 {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 5.3rem !important;
    justify-items: center;
}
p.card-titles-p {
    color: #C5C5C5;
}
.wrapper2 {
    margin-right: 20px;
    margin-top: -20px;
    margin-bottom: 50px;
}
ul.ul-walkfin {
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style-type: unset;
    margin-left: 15px;
    margin-top: 30px;
    font-size: 18px;
}
  ul.ul-walkfin li::marker {
    color: #A8FE39; /* Set the color of the bullet points */
  }
.streamlined-container{
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
}
ul.ul-walkfin1 {
    display: flex;
    flex-direction: row;
    gap: 30px;
    list-style-type: unset;
    margin-left: 15px;
      margin-top: 30px;
      font-size: 18px;
  }
  img.walkfin1 {
    margin-top: -160px;
}
  ul.ul-walkfin1 li::marker {
    color: #A8FE39; /* Set the color of the bullet points */
  }
  .img-titles-card1 {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 95px;
    margin-top: 54px;
}
.img-titles1-card1 {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 145px;
    margin-top: 110px;
}
img.wireframeimg {
    width: 981.75px;
    height: auto;
    flex-shrink: 0;
    margin-top: 55px;
}

.img-titles-card4 {
    display: flex;
    flex-direction: row;
    gap: 95px;
    margin-top: 54px;
}
div#problem-sec {
    margin-top: 56px;
}
.color {
    display: flex;
    gap: 43px;
    margin-top: 0;
    width: 149.149px;
    height: 183.152px;
    flex-shrink: 0;
    margin-left: 290px;
    margin-top: 35px;
}
p.card-titles1-p1 {
    margin-top: 10px;
}
p.card-titles1-p1 {
    width: 700px;
    height: auto;
    flex-shrink: 0;
    color: #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
p.card-titles-p1 {
    width: 460px;
    height: 29px;
    flex-shrink: 0;
    color: #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.img-titles-card-roboto {
    display: flex;
    gap: 32px;
    margin-top: 80px;

}
h4.card-titles-wireframe {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
}
p.card-titles-wireframe {
    width: 706px;
    color:  #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
img.wireframe {
    width: 242px;
    height: auto;
}
.walkfinuser-container {
    display: flex;
    flex-direction: row;
    gap: 139px;
    margin-left: -10px;
    margin-top: 50px;
}
img.mb1 {
    width: 320px;
    height: 645.926px;
    flex-shrink: 0;
    margin-top: -55px;
}
img.mb2 {
    width: 320px;
    height: auto;
    flex-shrink: 0;
    margin-top: 0;
}
div#enhanced-sec {
    margin-top: 60px;
}
.img-titles-card-pc2 {
    display: flex;
    gap: 84px;
}
p.card-titles-pc2 {
    width: 523px;
    color:  #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 172%;
    margin-top: 0;
}
.img-titles-card-wireframe {
    display: flex;
    align-items: flex-start;
    gap: 186px;
}
h4.card-titles1 {
    color: #A8FE39;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.25px;
    width: 200px;
}
p.card-titles-p {
    width: 706px;
}

img.hex2 {
    width: 522.571px;
    height: auto;
    flex-shrink: 0;
    margin-top: -100px;
    margin-left: -35px;
}
.img-titles-card-pc1 {
    display: flex;
    gap: 171px;
}
div#efficiency-sec {
    margin-top: 128px;
}
h4.card-titles1-pc2 {
    margin-top: 83px;
}
p.card-titles-pc1 {
    width: 523px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: #C5C5C5;
}

h4.card-titles1-pc1 {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
    margin-bottom: 15px;
}
h4.card-titles1-pc2 {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
    margin-bottom: 15px;
}

h4.card-titles1-hex2 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.25px;
}
.img-titles-card-hex2 {
    display: flex;
}
p.card-titles-p2 {
    width: 468px;
    color:  #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}
img.pc3 {
    width: 635px;
    height: auto;
    flex-shrink: 0;
}

p.card-titles-pc3 {
        width: 388px;
        position: relative;
        top: 90px;
        color: #C5C5C5;

/* Body text */
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 166.667% */
}
.img-titles-card-pc3 {
    display: flex;
    gap: 41px;
    margin-top: 76px;
}
h4.card-titles1-pc4 {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
    margin-top: 172px;
    margin-bottom: 15px;
}
img.pc4 {
    width: 608px;
    height: auto;
    flex-shrink: 0;
    margin-top: -55px;
}
p.card-titles-pc4 {
    width: 406px;
}
.img-titles-card-pc4 {
    display: flex;
    gap: 47px;
}
img.pc5 {
    width: 566px;
    height: auto;
    flex-shrink: 0;
    margin-top: 38px;
}
p.card-titles-pc5 {
    margin-top: 192px;
    width: 410px;
}
.img-titles-card-pc5 {
    display: flex;
    gap: 23px;
}
.img-titles-card-achieved1 {
    display: flex;
    gap: 111px;
}
div#wireframe-sec {
    margin-top: 60px;
}
h4.card-titles1-achieved1 {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
}
p.card-titles-achieved1 {
    display: flex;
    width: 662px;
    
    flex-shrink: 0;
    color:  #C5C5C5;
    /* Body text */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
}
div#achieved1-sec {
    margin-top: 160px;
}
img.roboto {
    width: 270px;
    height: auto;
    flex-shrink: 0;
}
p.card-titles-p1 {
    margin-top: 110px;
}
div#stream-sec {
    margin-top: 60px;
}

img.mb3 {
    width: 313.992px;
    height: auto;
    flex-shrink: 0;
    margin-top: -40px;
}
img.mb4 {
    width: 313.992px;
    height: auto;
    flex-shrink: 0;
}
h4.card-titles1-stream {
    width: 407px;
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
}
p.card-titles-stream {
    width: 554px;
    color:  #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
    margin-top: 20px;
}
.img-titles-card-stream {
    display: flex;
    gap: 140px;
}
.img-titles-card-stream1 {
    display: flex;
    gap: 142px;
    margin-top: 60px;
}
p.card-titles-stream1 {
    width: 540px;
    color: #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
    margin-top: 0;
}
img.mb5 {
    width: 314px;
    height: auto;
    flex-shrink: 0;
    margin-left: 400px;
    margin-top: -400px;
}
h4.card-titles1-enhance {
    width: 407px;
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
}
p.card-titles-enhance {
 width: 578px;
    color:  #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
    margin-top: 20px;
}
.img-titles-card-enhance {
    display: flex;
    gap: 110px;
}
.img-titles-card-enhance1 {
    display: flex;
    gap: 222px;
    margin-top: 60px;
}
p.card-titles-enhance1 {
    width: 512px;
    color: #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
    margin-top: 0;
}
img.mb6 {
    width: 320px;
height: auto;
flex-shrink: 0;
}
img.mb7 {
    width: 320px;
    height: auto;
    flex-shrink: 0;
}
div#enhance-sec {
    margin-top: 60px;
}
h4.card-titles2-enhance {
    width: auto;
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -2.422px;
}



@media screen and (max-width: 480px) {
        .main-wrapper1 {
            display: flex;
            flex-direction: column;
            align-items: unset;
            justify-content: center;
            gap: 0;
            justify-items: center;
            margin-left: 0;
            margin-top: 0;
            padding-left: 2vh;
            padding-right: 2vh;
        }
  
    p.card-titles-p {
        width: auto;
        font-size: 16px;
    }
    img.walkfin1 {
        margin-top: -110px;
    }
    div#walkfin-section {
        margin-top: 90px;
    }
    .ul-section {
        margin-top: 0;
    }
div#about-sec {
    margin-top: 0;
}
h4.card-titles1 {
    color: #A8FE39;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -0.25px;
    width: 200px;
}
.img-titles-card1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 0;
}

div#problem-sec {
    position: relative;
    top: -90px;
}
.img-titles-card4 {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 0;
}
.color {
    display: flex;
    margin-top: 0;
    width: 76px;
    height: auto;
    flex-shrink: 0;
    margin-left: 0;
    margin-top: 0;
    gap: 22px;
    margin-bottom: -40px;
}
.img-titles-card-roboto {
    display: flex;
    gap: 0;
    flex-direction: column-reverse;
}
p.card-titles-p1 {
    margin-top: 0;
    width: auto;
    height: auto;
    font-size: 16px;
}
.img-titles-card-wireframe {
    display: flex;
    align-items: flex-start;
    gap: 0;
    flex-direction: column;
    margin-top: 30px;
}
h4.card-titles-wireframe {
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -0.25px;
}
p{
    font-size: 16px !important;
    width: auto !important;
}
#visual-sec {
    margin-top: -70px;
}
.walkfinuser-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin: 0;
    align-items: center;
}
div#efficiency-sec {
    margin-top: 30px;
}
h4.card-titles1-pc1 {
    font-size: 18px;
    letter-spacing: -0.25px;
    margin-bottom: 0;
}
.img-titles-card-pc1 {
    display: flex;
    flex-direction: column;
    gap: 58px;
    align-items: center;
}
p.card-titles-pc2 {
    margin-top: 0;
}
img.mb2 {
    width: 320px;
    height: 645.926px;
    flex-shrink: 0;
    margin-top: 0;
}
.img-titles-card-pc2 {
    display: flex;
    gap: 12px;
    flex-direction: column-reverse;
    align-items: center;
}
div#enhanced-sec {
    margin-top: 20px;
}
div#stream-sec {
    margin-top: 20px;
}
h4.card-titles1-stream {
    font-size: 18px;
    letter-spacing: -0.25px;
}
.img-titles-card-stream {
    display: flex;
    gap: 45px;
    flex-direction: column;
    align-items: center;
}
p.card-titles-stream {
    margin-top: 0;
}
.img-titles-card-stream1 {
    display: flex;
    gap: 0;
    flex-direction: column-reverse;
    align-items: center;
}
.streamlined-container {
    width: auto;
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -0.25px;
}
p.card-titles-stream1 {
    width: 552px;
    color: #C5C5C5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
    margin-top: 0;
}
h4.streamlined-container {
    margin-top: 20px;
}
div#enhance-sec {
    margin-top: 30px;
}
h4.card-titles1-enhance {
    font-size: 18px;
    letter-spacing: -0.25px;
}
.img-titles-card-enhance {
    display: flex;
    gap: 12px;
        flex-direction: column;
    align-items: center;
}
p.card-titles-enhance {
    margin-top: 0;
}
.img-titles-card-enhance1 {
    display: flex;
    gap: 12px;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 30px;
}
p.card-titles-enhance1 {
    margin-top: 0;
}
.card-titles2-enhance {
    width: auto;
    color: #A8FE39;
    font-family: "Space Grotesk";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.21px;
    letter-spacing: -0.25px;
}
.img-titles-card-achieved1 {
    display: flex;
    gap: 0;
    flex-direction: column;
    align-items: flex-start;
}
div#achieved1-sec {
    margin-top: 30px;
}
h4.card-titles1-achieved1 {
    font-size: 18px;
    letter-spacing: -0.25px;
}
h4.card-titles2-enhance {
    font-size: 18px;
    letter-spacing: -0.25px;
}
h4.card-titles1.about {
    margin-top: 60px;
}
ul.ul-walkfin {
    margin-top: 20px;
    font-size: 16px;
}
.wrapper2 {
    margin-right: 0;
    margin-top: -20px;
    margin-bottom: 50px;
}
.img-titles1-card1 {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 145px;
    margin-top: 110px;
}
}

@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
.walkfin1 {
    width: 890px;
    height: auto;
    flex-shrink: 0;
    position: relative;
}
.nav-wrapper {
    display: none;
}
.img-titles-card1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 54px;
}
.img-titles-card4 {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 54px;
}
.color {
   
    margin-left: 0;
}
.img-titles-card-roboto {
    display: flex;
    gap: 32px;
    margin-top: 80px;
    flex-direction: column;
}
div#wireframe-sec {
    margin-top: 0 !important;
}
.img-titles-card-wireframe {
    display: flex;
    align-items: flex-start;
    gap: 0;
    row-gap: 0px;
    column-gap: 0px;
    flex-direction: column;
}
.walkfinuser-container {
    display: flex;
    flex-direction: row;
    gap: 0;
    margin-left: 0;
    margin-top: 50px;
    justify-content: center;
}
.img-titles-card-pc1 {
    display: flex;
    gap: 30px;
}
p.card-titles-pc1 {
    width: 360px;
}
.img-titles-card-pc2 {
    display: flex;
    gap: 30px;
}
.img-titles-card-stream {
    display: flex;
    gap: 50px;
}
p.card-titles-stream {
    width: 360px;
}
.img-titles-card-stream1 {
    display: flex;
    gap: 30px;
    margin-top: 60px;
}
.img-titles-card-enhance {
    display: flex;
    gap: 30px;
}
p.card-titles-enhance {
    width: 360px;
}
.img-titles-card-enhance1 {
    display: flex;
    gap: 30px;
    margin-top: 60px;
}
p.card-titles-enhance1 {
    width: 360px;
}
div#achieved1-sec {
    margin-top: 100px;
}
p.card-titles-achieved1 {
    display: flex;
}
.img-titles-card-achieved1 {
    display: flex;
    gap: 0;
    flex-direction: column;
}
img.walkfin1 {
    margin-top: 0;
}

.main-wrapper1 {
    display: flex !important;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 0 !important;
    justify-items: center;
    margin-left: 3rem;
        margin-top: 0;
}
.embedded-contact-form {
    display: none;
}
p.card-titles-pc2 {
    width: 360px;
}
p.card-titles-stream1 {
    width: 360px;
}
}