.span-style{
  color: #A8FE39;
}
.visual {
  margin-left: 290px;
}
.main-wrapper {
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 5.3rem !important;
  justify-items: center;
  margin-left: 5.3rem;
}
ul.ul-agam {
  display: flex;
  flex-direction: row;
  gap: 30px;
  list-style-type: unset;
  margin-left: 15px;
    margin-top: 20px;
    font-size: 18px;
}
ul.ul-agam li::marker {
  color: #A8FE39; /* Set the color of the bullet points */
}
.previous-next {
  margin-top: -30px;
}
.img-titles1-card2 {
  display: flex;
  flex-direction: row;
  gap:149px;
}
.img-title-card2 {
  display: flex;
  flex-direction: row;
  gap:139px;
}
.img-title-card3 {
  display: flex;
  flex-direction: row;
  gap:136px;
}
.img-title-card4 {
  display: flex;
  flex-direction: row;
  gap:136px;
}
.img-title-card5 {
  display: flex;
  flex-direction: row;
  gap: 80px;
  margin-top: 80px;
}
.item0 {
  margin-top: 80px;
}
.lead-insights {
  margin-top: 80px;
}
.img-title-card6 {
  display: flex;
  flex-direction: row;
  gap:63px;
}

img.mobile3 {
  width: 320px;
  height: auto;
  flex-shrink: 0;
  margin-top: -90px;
}
p#card-p4 {
  position: relative;
  top: 153px;
  width: 594px;
}
p#card-p3 {
  width: 532px;
}
h4#card1-h4 {
  align-self: stretch;
  width: 250px;
  line-height: 1.3;
  font-size: 30px;
}
img.mobile4 {
  width: 320px;
  height: auto;
  flex-shrink: 0;
}
#card-h4{
  width: 474px;
  margin-top: 120px;
}
#card-p{
  width: 474px;
}
h4#card1-h5 {
  width: 380px;
  color: #A8FE39;
  font-family: "Space Grotesk";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

img.mobile5 {
  width: 320px;
  height: auto;
  flex-shrink: 0;
  position: relative;
  top: -50px;
}
img.mobile6 {
  width: 320px;
  height: auto;
  flex-shrink: 0;
  position: relative;
  top: 0;
}
.img-title-card7 {
  display: flex;
  flex-direction: row;
  gap: 70px;
}
.img-title-cards {
  display: flex;
  flex-direction: row;
  gap: 112px;
  margin-top: 60px;
}
h4#h4-outcomes {
  color: #A8FE39;
  font-family: "Space Grotesk";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.21px;
  letter-spacing: -0.25px;
}
#p-outcomes {
  width: 710px;
  margin-top: 30px;
}
p#card-p6 {
  position: relative;
  top: 0;
  width: 580px;
}
h4#card6-h4 {
  width: auto;
}
p#card-p5 {
  width: 526px;
}

.mobile1 {
  width: 290px;
  height: auto;
  flex-shrink: 0;
  max-width: unset;
}
#card-heading {
  width: auto;
  margin-top: 0;
}
p#charts {
  position: relative;
  top: 0;
  width: 541px;
}
.mobile2 {
  width: 320px;
  height: auto;
  flex-shrink: 0;
  max-width: unset;
  position: relative;
  top: 0;
}
p#card-p1 {
  position: relative;
  top: 119px;
  width: 541px;
}
.problems-p{
  color: #A8FE39;
  width: auto;
text-align: center;
/* headings-case studu */
font-family: "Space Grotesk";
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: 45.21px; /* 161.464% */
letter-spacing: -2.422px;
margin-left: -100px;
    margin-bottom: 24px;
}
div#ideate-sec {
  margin-top: 111px;
}
.sitemap{
  display: flex;
width: 1013.357px;
padding-bottom: 61px;
flex-direction: column;
align-items: flex-end;
margin-top: 90px;
}
.wireframe-sec{
  margin-top: 60px;
}
.wireframes-mobile {
  display: flex;
  flex-direction: row;
  gap: 93px;
}
.wireframes{
  margin-top: 75px;
  display: flex;
width: 272px;
height: 553.992px;
justify-content: center;
align-items: flex-start;
gap: 93px;
flex-shrink: 0;
}
#visual1-sec{
  margin-top: 140px;
}
.visual-div {
  display: flex;
  align-items: center;
  gap: 35px;
  margin-top: 95px;
  flex-direction: row;
}
p.card-title-p.poppins-p {
  margin-top: -190px;
}
.img-title-cards3 {
  display: flex;
  flex-direction: row;
  gap: 136px;
  margin-top: 80px;
}
.visual{
  width: 301px;
  height: 301px;
}
.poppins{
  width: 270px;
  height: 323px;
}
p.card-title-p.users {
  position: relative;
  margin-top: -500px;
}
.wrapper1 {
  margin-right: 20px;
  margin-top: -20px;
  margin-bottom: 50px;
}

img.dashboard-screen {
  margin-top: 130px;
}
.active{
  color:#a8fe39;
  background-color: none !important;
}
.sticky-nav-back-to-home1 {
  height: 88px;
  background-color: rgba(14, 14, 14, .2);
  backdrop-filter: blur(5px);
  padding-top: 16px;
  padding-bottom: 16px;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.w-nav1 {
  z-index: 1000;
  position: relative;
}
.nav-div1 {
  height: 52px;
  background-color: transparent;
      justify-content: space-between;
  align-items: center;
  margin: 0 57px;
  display: flex;
}
.brand {
  align-items: center;
  display: flex;
}
.w-nav-brand {
  float: left;
  color: #333;
  text-decoration: none;
  position: relative;
}
.back-to-home1 {
  color:#a8fe39;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 12px;
  font-size: 15px;
}
.w-nav-button {
  float: right;
  cursor: pointer;
  user-select: none;
  padding: 18px;
  font-size: 24px;
  display: none;
  position: relative;
}
.w-icon-nav-menu{
  speak: none;
  font-variant: normal;
  text-transform: none;
 
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}
.contact-us-btn-nav {
  border: 0.75px solid #ecfe08;
  color: #ecfe08;
  background-color: rgba(236, 254, 8, .1);
  border-radius: 12px;
  padding: 8px 24px;
  font-family: Space Grotesk, sans-serif;
  font-size: 1.2vw;
  line-height: 30px;
}
.divider-div-nav {
  height: 0.75px;
  border: 0.75px solid rgba(235, 235, 235, .4);
  margin-top: 105px;
  margin-left: 57px;
  margin-right: 57px;
  display: none;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}
.w-nav-overlay {
  width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}


.nav-wrapper {
  width: auto;
  margin-top: 8vh;
  position: fixed;
  scroll-behavior: smooth;
}

.main-wrapper {
  width: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 16vh;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}
.nav-main-div {
  width: auto;
  margin-right: auto;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
  position: relative;
  margin-right: 85px;
}
.cs-sidenav-link:hover, .cs-sidenav-link.w--current {
  color: #a8fe39;
}

.cs-sidenav-link {
  width: auto;
  height: auto;
  color: #a7a7a7;
  align-items: center;
  margin-bottom: 2vh;
  font-family: Space Grotesk, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 25.8px;
  text-decoration: none;
  display: flex;
  white-space: nowrap;
  display: flex !important;
  flex-direction: row;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
a {
  background-color: rgba(0, 0, 0, 0);
}

.side-nav {
  margin-bottom: 0;
  margin-left: 0;
  font-family: Space Grotesk, sans-serif;
  font-size: 14px;
}



.img-title-card1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  
}
#cards-h4 {
  width: auto;
  margin-top: 0;
}
p#cards-p4 {
  position: relative;
  top: 10px;
  width: 594px;
}
.card-title1{
color: #a8fe39;
letter-spacing: -0.25px;
margin-top: 20px;
margin-bottom: 10px;
font-family: Space Grotesk, sans-serif;
font-size: 24px;
font-weight: 400;
width: 150px;
}

.card-title-p{
color: #c5c5c5;
margin-bottom: 20px;
font-family: Inter, sans-serif;
font-size: 18px;
line-height: 28px;
margin-top: 1vh;
margin-bottom: 3vh;
font-family: Inter, sans-serif;
width: 703px;
}

.grid-admission{
display: grid;
grid-template-columns: 600px 500px;
}
.grid-admission2{
display: grid;
grid-template-columns: 500px 600px;
}
h4,h2,h3{
font-family: Space Grotesk, sans-serif;
}

p{
font-family: Inter, sans-serif;
}







@media screen and (max-width: 480px) {
  div#agam {
    margin-top: 150px !important;
  }
  .sticky-nav-back-to-home1 {
    height: auto;
    background-color: rgba(14, 14, 14, .2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding-top: 0;
    padding-bottom: 0;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
  }
  
  .grid-admission,
  .grid-admission2 {
    grid-template-columns: 1fr;
  }
  .card-title1 {
    font-size: 18px;
    line-height: 36px;
    letter-spacing: -0.25px;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
}
 .card-title-p {
    width: 100%;
    font-size: 16px;
    line-height: 30px;
  }
  div#ideate-sec {
    margin-top: 20px;
}
.sitemap {
  display: flex;
  width: auto;
  padding-bottom: 0;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}
div#wireframe-sec {
  margin-top: 0 !important;
}
.wireframes {
  margin-top: 0;
  width: auto;
    height: auto;
}
.visual {
  margin-left: 0;
}
p.card-title-p.poppins-p {
  margin-top: 12px;
}
.poppins {
  width: 270px;
  height: 323px;
  margin-top: -450px;
}

#visual1-sec {
  margin-top: 0;
}
.visual-div {
  display: flex;
  align-items: center;
  gap: 436px;
  margin-top: 0;
  flex-direction: column-reverse;
}
#navbarMenu{
 
    backdrop-filter: blur(40px);
    background-color: rgba(14, 14, 14, .9);
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
   
    display: flex;

}
.main-wrapper {
  
  flex-direction: column;
}

  .mobile-navbar-menu {
      gap: 45px;
  }

  
  .side-nav {
    display: none;
}
.nav-wrapper {
margin-top: 0;
}
.main-wrapper {
    margin-top: 0;
    padding-left: 2vh;
    padding-right: 2vh;
}
.nav-div1 {
  
  margin: 0 16px;
}
a.contact-us-btn-nav.w-button {
  display: none;
}

ul#navbarMenu {
  gap: 40px;
    flex-shrink: 0;
}

.navbarmenu1{
  margin-left: 110px;
}
.contact-us-btn-nav {
  display: none;
}
.wrapper1 {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 20px;
}

img.discover {
  display: flex;
  align-items: center;
  margin-top: -90px;
  padding: 0;
}
.img-title-card2 {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.img-titles1-card2 {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.problems-p {
  color: #A8FE39;
  width: auto;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: normal;
  margin-left: 0;
  margin-bottom: 24px;
}

.wireframes-mobile {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.img-title-card3 {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center !important;

}
#card-h4 {
  width: 474px;
  margin-top: 60px;
}
#card-p {
  width: auto;
}
.img-title-cards3 {
  display: flex;
  flex-direction: column-reverse;
  gap: 0;
  margin-top: 60px;
  align-items: center ;

}
p#charts {
  position: relative;
  top: 0;
  width: auto;
}
.img-title-card4 {
  display: flex;
  flex-direction: column !important;
  gap: 90px !important;
  align-items: center;

}
p#card-p5 {
  width: auto;
}
p#card-p3 {
  width: auto;
}
h4#card1-h4 {
  align-self: stretch;
  width: 250px;
  line-height: 1.3;
  font-size: 18px;
}
.img-title-card5 {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
  margin-top: 60px;
  align-items: center;

}
p#cards-p4 {
  position: relative;
  top: 0;
  width: auto;
}
h4#card1-h5 {
  font-size: 18px; 
}
.img-title-card6 {
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: center;

}
.img-title-card7 {
  display: flex;
  flex-direction: column-reverse;
  gap: 0px;
  align-items: center;
}
p#card-p6 {
  position: relative;
  top: 0;
  width: auto;
}
.img-title-cards {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: 60px;
}
h4#h4-outcomes {
  font-size: 18px;
}
#p-outcomes {
  width: auto;
  margin-top: 0;
}

.embedded-contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
  margin-top: -60px;
  margin-bottom: 60px;

}
.menu-icons {
  display: block;
}
div#problem1-sec {
  position: relative;
  top: 0px;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: unset;
  justify-content: center;
  gap: 0;
  justify-items: center;
  margin-left: 0;
}

}
div#discover-sec {
  margin-top: 0 !important;
}


/* Embedded contact form styles */
.embedded-contact-form .contact-form {
  position: static; /* Ensure it's part of the normal page flow */
  top: auto;
  left: auto;
  transform: none;
  background: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}

.embedded-contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}
@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
  .embedded-contact-form {
    display: none;
}
.img-titles1-card2 {
  display: flex;
  flex-direction: column;
  gap: 0;
}
#card-p {
  width: 360px;
}
.img-title-card2 {
  display: flex;
  flex-direction: column;
  gap: 0;
}
.problems-p {
  margin-left: 0;
  text-align: center;
}
.wrapper1 {
  margin-right: 20px;
  margin-top: -20px;
  margin-bottom: 50px;
}
/* .mobile1, .mobile2, .mobile3, .mobile4, .mobile5, .mobile6{
  width: 63% !important;
} */

div#ideate-sec {
  margin-top: 80px;
}
.sitemap {
  display: flex;
  width: 90%;
  padding-bottom: 61px;
  margin-top: 0;
}
img.wireframes {
  width: 31%;
}
div#wireframe-sec {
  margin-top: -80px !important;
}
.wireframes-mobile {
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: center;
  margin-right: 0;
}
.wireframes {
  margin-top: 0;
}
#visual1-sec {
  margin-top: 80px;
}

.visual-div {
  display: flex;
  align-items: flex-start;
  gap: 250px;
  margin-top: 95px;
  flex-direction: column;
}
#card-h4 {
  width: 474px;
  margin-top: 50px;
}
.img-title-card3 {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 60px;
}
.main-wrapper {
  margin-left: 0;
}
p#charts {
  position: relative;
  top: 0;
  width: 360px;
}
p#card-p3 {
  width: 360px;
}
.img-title-card4 {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

p#cards-p4 {
  position: relative;
  top: 10px;
  width: 360px;
}
p#card-p5 {
  width: 360px;
}
p#card-p6 {
  position: relative;
  top: 0;
  width: 360px;
}
.img-title-card7 {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.embedded-contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.img-title-cards {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-top: 60px;
}
.nav-main-div {
  margin-right: 0;
}
#p-outcomes {
  width: 810px;
  margin-top: 0;
}
.main-wrapper {
  display: flex;
  flex-direction: column; /* Column layout for stacking content on smaller screens */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  margin-left: 3rem;
  margin-right: auto;
  margin-top: 6rem;
  gap: 0 !important;
}

.next-container {
  display: flex;
  gap: 16px;
  margin-top: 154px;
  margin-right: 0;
}



img.discover {
  width: auto;
  display: flex;
  flex-shrink: 0;
}
.embedded-contact-form {
  display: none;
}
}
