.hero-section{
  position: relative;
  height: auto; /* Adjust height as needed */
  overflow: hidden;
  background-image: url('../assets/herosection.gif');
  background-size: cover;
  background-position: center;
}

.background-gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind the content */
}

.hero-content {
  color: #fff;
  z-index: 1;
  font-family: "Space Grotesk";
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5.875rem;
  letter-spacing: -0.10313rem;
}
div#hero {
  margin-left: 0;
  margin-right: 0;
}
.hero-contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10.9rem;
  margin-left: 0;
}
.div-block {
  display: flex;
  flex-direction: column;
  margin-left: 8.63rem;
  margin-right: 8.15rem;
}
    
h1 {
  font-size: 88px;
  line-height: 94px;
  font-family: Space Grotesk, sans-serif;
  font-weight: 400;
}

.ellipse-div {
  z-index: -10;
  width: 70vw;
  height: 30vh;
  border: 1px solid var(--typo);
  filter: blur(1200px);
  /* background-image: linear-gradient(#59ebe8, #af3d8c); */
  background: #0E0E0E;
  border-radius: 600px;
  margin-top: 0;
  margin-left: 10vw;
  display: block;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.span1 {
  color: #a8fe39;
}

.scroll-button {
  width: 85px;
  height: 80px;
  z-index: 1;
  position: relative;
}

.overlay-gradient {
  background-image: linear-gradient(to bottom, transparent 59%, #0e0e0e);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

/* Media Queries */

/* Mobile Screens */
@media screen and (max-width: 480px) {
  .hero-section {
    height: auto;
    margin: 0;
    background-image: none;
  }
  .background-hero {
    background-image: none;
}
  .ellipse-div {
    z-index: -10;
    width: 0;
    height: 0;
    border: 1px solid var(--typo);
    filter: blur(1200px);
    background-image: linear-gradient(#59ebe8, #af3d8c);
    border-radius: 600px;
    margin-top: 0;
    margin-left: 0;
    display: block;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;
}
.div-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0;
}
.hero-contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 5.5rem;
  margin-left: 0;
  align-items: center;
}
.hero-content {
  text-align: center;
  letter-spacing: -3px;
  font-size: 2.5rem;
  line-height: 1.2;
  max-width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  position: relative;
  top: 0;
  margin-top: 0;
}
img.scroll-button {
  position: relative;
  top: 0;
  left: 0;
}
.scroll-button {
  position: relative;
  margin: 45px;
    text-align: center;
  transform: translateY(-50%);
  z-index: 1;
}
  .hero-content h1 br {
    display: inline; /* Show line breaks on mobile screens */
  }
  div#hero {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {

  .scroll-button {
    margin-left: 0;
}
    .hero-content {
      color: #fff;
      z-index: 1;
      font-family: "Space Grotesk";
      font-size: 3rem;
      font-style: normal;
      font-weight: 400;
      line-height: 3.875rem;
      letter-spacing: -0.10313rem;
  }
  .div-block {
    display: flex;
    flex-direction: column;
    margin-left: 2.63rem;
    margin-right: 2.15rem;
}
.hero-contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 6rem;
  margin-left: 0;
}
  
}
