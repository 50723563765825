



.slider-container1{
  color: #c5c5c5;
    text-align: center;
    font-style: italic;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
}
.testimonials-section {
  width: auto;
  height: auto;
  margin-bottom: 0;
  position: relative;
  margin-top: 80px;
}
.testimonial-div {
  width: auto;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  top: 0;
  position: relative;
}
.testimonial-heading-div {
  justify-content: space-around;
  margin-bottom: 80px;
    display: flex;
}
.testimonial-heading {
  width: 370.5px;
  text-align: center;
  margin-top: 10px;
    margin-bottom: 10px;
    font-family: Space Grotesk, sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 37.5px;
}

.author {
  color: #a8fe39;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 6px;
  font-weight: 600;
  text-transform: uppercase;
  font-style: normal;
}

.role {
  color: #7d7d7d;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-style: normal;

}
/* .dot1 {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  transition: 0.5s;
}
.dotss {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: -100px;
} */

.dotss {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: -100px;
}

.dot1 {
  position: relative;
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: transparent; /* Inner circle fill color */
  border: 0.75px solid #ccc; /* Outer circle stroke color */
  border-radius: 50%;
  display: inline-block;
  transition: 0.5s;
}

.dot1::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px; /* Adjust size for inner circle */
  height: 7px; /* Adjust size for inner circle */
  background-color: #A8FE39;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s;
}

.dot1:hover::before,
.dot1.active::before {
  opacity: 1; /* Show on hover or active */
}

.dot1:hover {
  border-color: #A8FE39;
}

.dot1.active {
  border-color: #A8FE39;
}

p{
  font-weight: 400;
}

.testimonials {
  margin-top: -50px;
  color: #c5c5c5;
  text-align: center;
  font-style: italic;
  font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6875rem;

}
p {
  margin-bottom: 10px;
  font-family: Inter, sans-serif;
  font-size: 18px;
  line-height: 28px;
}
.testimonial-content{
width: 870px;
height: 198px;
mix-blend-mode: lighten;
}

@media screen and (max-width: 480px){
  .testimonials-section {
    width: auto;
    margin-bottom: 0;
    margin-left: 2vh;
    margin-right: 2vh;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
}
.testimonial-heading {
    line-height: 30px;
    width: 676px;
    margin-bottom: -40px;
    margin-top: -20px;

}
.testimonial-div {
  width: auto;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  top: 0;
  position: relative;
  margin-top: 3.75rem;
}
.testimonials {
  font-size: 14px;
  line-height: 22px;
  margin-top: 0px;
  width: 350px !important;
}
.testimonial-content {
  width: auto;
  height: auto;
  mix-blend-mode: lighten;
}
}



  @media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
  .testimonial-content {
    width: auto;
    height: auto;
    mix-blend-mode: lighten;
    margin-left: 50px;
    margin-right: 50px;
}
}