
    .services-section {
        width: 100vw;
        height: 100vh;
        margin-top: 30vw;
    }
.serviceSection{
    background-color: #0e0e0e;

}
.serviceGrid.grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
        margin-left: 5.63rem;
    margin-right: 4.3rem;
}
div#services {
    margin-top: 4.63rem;
}

/* .serviceGrid {
    display: flex;
    grid-auto-columns: 1fr;
    grid-row-gap: 16px;
}
.serviceDiv{
    display: flex;
width: auto;
height: auto;
justify-content: center;
align-items: flex-start;
gap: 1.875rem;
flex-shrink: 0;} */
.serviceHeader{
color: white;
font-size: 24px;
font-family: Space Grotesk;
font-weight: 400;
line-height: 37.50px;
word-wrap: break-word;
text-align: center;
    margin-top: 0;
    margin-bottom: 38px;
}

/* .grid {
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
} */

/* .serviceGrid{
    display: grid;
    grid-auto-columns: 1fr;
    grid-row-gap:16px ;
} */
.serviceGrid.grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.text-block {
    font-size: 18px;
    line-height: 34px;
}

.services-sidebar{
    display: flex;
    margin-top: 36px;
    flex-direction: column;
  }

  .services-p-text{
    font-size: 16px;
    line-height: 24px;
    position: relative;
    top: 0px;
  }

  @media screen and (max-width: 480px){
    .serviceSection {
        width: 100vw;
        height: auto;
        margin: 0vh 0 5vw;
        padding-left: 0;
        padding-right: 0;
    }
    .serviceGrid.grid {
        margin-right: 0;
    }
    div#services {
        margin-top: 0;
    }
   .serviceDiv {
        width: auto;
        height: auto;
        margin: auto;
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
    }
    .serviceHeader{
        margin-bottom: 4vh 
    }

    .grid {
        grid-column-gap: 0;
        grid-row-gap: 0;
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        height: auto;
        justify-items: center;
        margin-bottom: -115px;
        }
    .services-p-text{
        display: none;
    }
    .serviceGrid.grid {
        margin-left: 0;
        margin-right: 0;
    }
    
  }

  



  @media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
    .serviceGrid.grid {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        margin-left: 0;
        margin-right: 0;
    }
    .services-p-text {
        font-size: 16px;
        line-height: 24px;
        position: relative;
        margin-top: 20px;
        text-align: center;
    }
    .serviceGrid.grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 0;
        justify-items: center;
        align-content: center;
        align-items: center;
    }
}

