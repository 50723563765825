.previous{
    width: 70px;
height: 70px;
flex-shrink: 0;

}
.previous-container {
    display: flex;
    gap: 16px;
    margin-top: 154px;
}
img.next {
    width: 70px;
    height: 70px;
    flex-shrink: 0;
}
.next-container {
    display: flex;
    gap: 16px;
    margin-top: 154px;
    margin-right: 140px;
}
.previous-next {
    display: flex;
    justify-content: space-between;
}



@media screen and (max-width: 480px) {

.next-container {
    display: flex;
    gap: 16px;
    margin-top: 50px;
    margin-right: 0;
  }
  .previous-container {
    display: flex;
    gap: 16px;
    margin-top: 50px;
  }
  .previous-next {
    display: flex;
    justify-content: space-between;
    gap: 65px;
  }
  .walkfins-admin {
    display: none;
}
.walkfins {
    display: none;
}
}

@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {
.next-container {
    margin-right: 0;
}
.previous-next {
    display: flex;
    justify-content: unset;
    gap: 400px;
    flex-direction: row;
}
}
