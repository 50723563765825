.mission-section {
    width: auto;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    padding-left: 8.65rem;
    padding-right: 8.15rem;
}
.mission-h4 {
    font-size: 1.5rem;
    line-height: 2.34375rem;
}
.mission-div {
    width: auto;
    height: auto;
    max-width: none;
    grid-column-gap: 7vw;
    grid-row-gap: 16px;
    grid-template-columns: minmax(19.5385rem, 400px) minmax(19.5385rem, 400px) minmax(19.5385rem, 400px);
    grid-auto-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
    display: grid;
}

#mission1{
    grid-area: span 1 / span 1 / span 1 / span 1;
}

.mission-icon {
    margin-bottom: 16px;
}
.mission-para {
    color: #c5c5c5;
    font-size: 1.3rem;
    line-height: 2.34375rem;
    margin-bottom: 10px;
}

#mission2{
    grid-area: span 1 / span 1 / span 1 / span 1;
}
.mission-icon-descp-div {
    width: 297.75px;
}

#mission3{
    grid-area: span 1 / span 1 / span 1 / span 1;
}


@media screen and (max-width: 480px){
.mission-div{
    display: flex;
flex-direction: column;
gap: 30px;
margin-top: 20px;
}
.mission-para {
    font-size: 14px;
    line-height: 18px;
}
.mission-h4{
    color:  #FFF;
font-family: "Space Grotesk";
font-size: 1.25rem;
font-style: normal;
font-weight: 400;
line-height: 2.34375rem; /* 187.5% */
}

.mission-para{
    color: var(--Monochrome-Grey-6, #C5C5C5);
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 2.34375rem;
width: 19.5385rem;
}
.mission-section {
    width: auto;
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    padding-left: 2vh;
    padding-right: 1.96rem;
}
.mission-icon-descp-div {
    width: auto;
}
}



@media only screen 
and (min-width: 600px)
and (max-width: 1264px) {

    .mission-section {
        display: flex;
        margin: 50px;
    }
    .mission-section {
        width: auto;
        height: auto;
        margin-top: 50px;
        margin-bottom: 0;
        display: flex;
        padding-left: 0;
        padding-right: 0;
    }
    .mission-div {
        display: flex;
        flex-direction: column;
    }
    .mission-icon-descp-div {
        width: auto;
    }
}