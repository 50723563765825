.navDesktopMenuBtn1{
    display: none;
}

  
  
/* ContactUs.css */
.modal-open {
    overflow: hidden !important; /* Prevent scrolling on the background */
  }
  .modal-content {
    position: relative;
    z-index: 40;
  }
@media screen and (max-width: 480px){
.navDesktopMenuBtn1{
    z-index: 5;
    height: 72px;
    background-color: #a8fe39;
    color: #000;
    border-width: 0.75px;
    border-color: #ecfe08;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: Space Grotesk, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    position: fixed;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
    border-radius:0px;
    border:none;
}

}